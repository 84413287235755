/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const ArrowDown: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path fillRule='evenodd' clipRule='evenodd' d='M4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L12 17.5858L18.2929 11.2929C18.6834 10.9024 19.3166 10.9024 19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M12 3C12.5523 3 13 3.44772 13 4V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V4C11 3.44772 11.4477 3 12 3Z' fill='currentColor' />
        </svg>;
};

export default ArrowDown;